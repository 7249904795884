import { shaderMaterial } from "@react-three/drei";
import glsl from "babel-plugin-glsl/macro";
import { Color, Vector3 } from "three";

const SphereShaderMaterial = shaderMaterial(
  {
    uLightColor: new Color(1, 0, 0),
    uLightIntensity: 1,

    uOffset: new Vector3(),

    uDistortionFrequency: 0,
    uDistortionStrength: 0,
    uDisplacementFrequency: 0,
    uDisplacementStrength: 0,

    uTime: 0,
  },
  glsl`
  #define M_PI 3.1415926535897932384626433832795

uniform vec3 uLightColor;
uniform float uLightIntensity;

uniform vec3 uOffset;

uniform float uDistortionFrequency;
uniform float uDistortionStrength;
uniform float uDisplacementFrequency;
uniform float uDisplacementStrength;

uniform float uTime;

varying vec3 vColor;

varying vec3 vPositionW;
varying vec3 vNormalW;

#pragma glslify: perlin4d = require('./partials/perlin4d.glsl')

vec3 getDisplacedPosition(vec3 _position)
{
    vec3 distortedPosition = _position;
    distortedPosition += perlin4d(vec4(distortedPosition * uDistortionFrequency + uOffset, uTime)) * uDistortionStrength;

    float perlinStrength = perlin4d(vec4(distortedPosition * uDisplacementFrequency + uOffset, uTime));

    vec3 displacedPosition = _position;
    displacedPosition += normalize(_position) * perlinStrength * uDisplacementStrength;

    return displacedPosition;
}

void main()
{
    // Position
    vec3 displacedPosition = getDisplacedPosition(position);
    vec4 viewPosition = viewMatrix * vec4(displacedPosition, 1.0);

    // Varying
    vec3 color = vec3(0.0);
    color = mix(color, uLightColor, uLightIntensity);
    vColor = color;

    vPositionW = normalize(vec3(modelViewMatrix * viewPosition).xyz);
    vNormalW = normalize(normalMatrix * normal);
    gl_Position = projectionMatrix * modelViewMatrix * viewPosition;

    // vPositionW = normalize(vec3(modelViewMatrix * vec4(displacedPosition, 1.0)).xyz);
    // vNormalW = normalize(normalMatrix * normal);
    // gl_Position = projectionMatrix * modelViewMatrix * vec4( displacedPosition, 1.0 );
}
`,
  glsl`
    varying vec3 vColor;
    varying vec3 vPositionW;
    varying vec3 vNormalW;
    void main() {
      float fresnelTerm = (1.1 - -min(dot(vPositionW, normalize(vNormalW) ), 0.0));
      gl_FragColor = vec4(vColor.r, vColor.g, vColor.b, 0.4) * vec4(fresnelTerm);
    }
  `
);

export default SphereShaderMaterial;
