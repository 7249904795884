import { Spherical, Vector3, NormalBlending } from "three";
import { extend, useFrame } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import { Mesh, ShaderMaterial } from "three";
import SphereShaderMaterial from "./shaders/sphereShader";
import { useGarageContext } from "../../../../../context/garageContext";
import AudioListener from "../../../../../lib/AudioListener/AudioListener";

extend({ SphereShaderMaterial });

const deltaOffset = 10;

type SphereProps = {
  number: number;
  radius: number;
  distortionFrequency: number;
  displacementFrequency: number;
  distortionFrequencyIdle: number;
  distortionStrengthIdle: number;
  displacementFrequencyIdle: number;
  displacementStrengthIdle: number;
};

type VariationType = {
  target: number;
  current: number;
  upEasing: number;
  downEasing: number;
  getDefault: () => number;
  getValue: () => number;
};

type VariationsType = {
  volume: VariationType;
  lowLevel: VariationType;
  mediumLevel: VariationType;
  highLevel: VariationType;
};

export default function Sphere({
  number,
  radius = 1,
  distortionFrequency,
  displacementFrequency,
  distortionFrequencyIdle,
  distortionStrengthIdle,
  displacementFrequencyIdle,
  displacementStrengthIdle,
}: SphereProps) {
  const audioListenerRef = useRef(new AudioListener());
  const { audioPlaying } = useGarageContext();
  const meshRef = useRef<Mesh>(null);
  const sphereMaterial = useRef<ShaderMaterial>();
  const offsetRef = useRef({
    spherical: new Spherical(
      radius,
      Math.random() * Math.PI,
      Math.random() * Math.PI * 2
    ),
    direction: new Vector3(),
  });
  const variations = useRef<VariationsType>({
    volume: {
      target: 0,
      current: 0,
      upEasing: 0.03,
      downEasing: 0.002,
      getDefault: () => 0.152,
      getValue: () => {
        const level0 = audioListenerRef.current.levels[0] || 0;
        const level1 = audioListenerRef.current.levels[1] || 0;
        const level2 = audioListenerRef.current.levels[2] || 0;

        const value = Math.max(
          variations.current.volume.getDefault(),
          Math.max(level0, level1, level2) * 0.3
        );

        return value;
      },
    },
    lowLevel: {
      target: 0,
      current: 0,
      upEasing: 0.005,
      downEasing: 0.002,
      getDefault: () => 0.0003,
      getValue: () => {
        let value = audioListenerRef.current.levels[0] || 0;
        value *= 0.003;
        value += 0.0001;
        value = Math.max(variations.current.lowLevel.getDefault(), value);

        return value;
      },
    },
    mediumLevel: {
      target: 0,
      current: 0,
      upEasing: 0.008,
      downEasing: 0.004,
      getDefault: () => 3.587,
      getValue: () => {
        let value = audioListenerRef.current.levels[1] || 0;
        value *= 2;
        value += 3.587;
        value = Math.max(variations.current.mediumLevel.getDefault(), value);

        return value;
      },
    },
    highLevel: {
      target: 0,
      current: 0,
      upEasing: 0.02,
      downEasing: 0.001,
      getDefault: () => 0.65,
      getValue: () => {
        let value = audioListenerRef.current.levels[2] || 0;
        value *= 5;
        value += 0.5;
        value = Math.max(variations.current.highLevel.getDefault(), value);

        return value;
      },
    },
  });

  useFrame((state, delta) => {
    if (meshRef.current && sphereMaterial.current) {
      meshRef.current.rotation.z += 0.0001 * number;

      for (let _variationName in variations.current) {
        // @ts-ignore
        const variation = variations.current[_variationName];
        variation.target = variation.getValue();

        const easing =
          variation.target > variation.current
            ? variation.upEasing
            : variation.downEasing;

        variation.current +=
          (variation.target - variation.current) *
          easing *
          (delta + deltaOffset);
      }

      // Time
      const timeFrequency = variations.current.lowLevel.current;
      const elapsedTime = (delta + deltaOffset) * timeFrequency;

      // Update material
      sphereMaterial.current.uniforms.uDisplacementStrength.value =
        variations.current.volume.current;
      sphereMaterial.current.uniforms.uDistortionStrength.value =
        variations.current.highLevel.current;

      // Offset
      const offsetTime = elapsedTime * 0.3;
      offsetRef.current.spherical.phi =
        (Math.sin(offsetTime * 0.001) * Math.sin(offsetTime * 0.00321) * 0.5 +
          0.5) *
        Math.PI;
      offsetRef.current.spherical.theta =
        (Math.sin(offsetTime * 0.0001) * Math.sin(offsetTime * 0.000321) * 0.5 +
          0.5) *
        Math.PI *
        2;
      offsetRef.current.direction.setFromSpherical(offsetRef.current.spherical);
      offsetRef.current.direction.multiplyScalar(timeFrequency * 2);

      sphereMaterial.current.uniforms.uOffset.value.add(
        offsetRef.current.direction
      );

      // Time
      sphereMaterial.current.uniforms.uTime.value += elapsedTime;
    }
  });

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.geometry.computeTangents();
      offsetRef.current.direction.setFromSpherical(offsetRef.current.spherical);
    }
  }, []);

  return (
    <mesh ref={meshRef}>
      <sphereGeometry args={[radius, 512, 512]} />
      {/* @ts-ignore */}
      <sphereShaderMaterial
        ref={sphereMaterial}
        blending={NormalBlending}
        attach="material"
        uLightColor="#ff0000"
        uLightIntensity={1.0}
        uOffset={offsetRef.current.direction}
        uDistortionFrequency={
          audioPlaying ? distortionFrequency : distortionFrequencyIdle
        }
        uDistortionStrength={distortionStrengthIdle}
        uDisplacementFrequency={
          audioPlaying ? displacementFrequency : displacementFrequencyIdle
        }
        uDisplacementStrength={displacementStrengthIdle}
        uTime={0}
        depthWrite={false}
        transparent={true}
        alphaTest={0.5}
        opacity={0.5}
      />
    </mesh>
  );
}
