import React, { createContext, ReactNode, useContext, useState } from "react";

interface AiLoadingContextType {
  isAiLoadingOpen: boolean;
  toggleAiLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialAiLoadingContextState: AiLoadingContextType = {
  isAiLoadingOpen: false,
  toggleAiLoading: () => {
    throw new Error(
      "You forgot to wrap this in a AiLoadingContextProvider object"
    );
  },
};

const AiLoadingContext = createContext<AiLoadingContextType>(
  initialAiLoadingContextState
);

export function useAiLoadingContext(): AiLoadingContextType {
  return useContext(AiLoadingContext);
}

type AiLoadingContextProviderProps = {
  children: ReactNode;
};

export const AiLoadingContextProvider = (
  props: AiLoadingContextProviderProps
) => {
  const [isAiLoadingOpen, toggleAiLoading] = useState(false);

  return (
    <AiLoadingContext.Provider
      value={{
        isAiLoadingOpen,
        toggleAiLoading,
      }}
    >
      {props.children}
    </AiLoadingContext.Provider>
  );
};

export default AiLoadingContext;
