import { Html } from "@react-three/drei";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import type { Group } from "three";
import { useAiLoadingContext } from "../../../../../context/aiLoadingContext";
import { useDeviceStateTracker } from "../../../../../hooks/useDeviceStateTracker";
import Sphere from "../sphere/Sphere";
import AudioListener from "../../../../../lib/AudioListener/AudioListener";

export default function Orb({
  start,
  scale = 1,
  ...props
}: { start?: boolean; scale?: number } & JSX.IntrinsicElements["group"]) {
  const orbRef = useRef<Group>(null);

  const { deviceState } = useDeviceStateTracker(true);
  const { isAiLoadingOpen } = useAiLoadingContext();
  const audioListenerRef = useRef(new AudioListener());
  const orbInitialised = useRef(false);

  useEffect(() => {
    audioListenerRef?.current.loadAudio("/assets/audio/IntroAI-Welkom.mp3");
  }, [audioListenerRef]);

  useEffect(() => {
    if (orbRef.current && start && !orbInitialised.current) {
      gsap.timeline().fromTo(
        orbRef.current.scale,
        {
          x: 0,
          y: 0,
          z: 0,
        },
        {
          x: scale,
          y: scale,
          z: scale,
          duration: 1.5,
          ease: "power4.out",
          onComplete: () => {
            if (!orbInitialised.current) {
              orbInitialised.current = true;
              audioListenerRef?.current.playAudio();
            }
          },
        }
      );
    }
  }, [scale, start]);

  return (
    <group {...props} scale={[0, 0, 0]} ref={orbRef}>
      <Sphere
        number={1}
        radius={1.02}
        distortionFrequency={0.652}
        displacementFrequency={0.543}
        distortionFrequencyIdle={0.652}
        distortionStrengthIdle={0.761}
        displacementFrequencyIdle={0.87}
        displacementStrengthIdle={0.196}
      />
      <Sphere
        number={2}
        radius={1}
        distortionFrequency={0.435}
        displacementFrequency={0.543}
        distortionFrequencyIdle={0.435}
        distortionStrengthIdle={0.543}
        displacementFrequencyIdle={1.304}
        displacementStrengthIdle={0.054}
      />
      <Sphere
        number={3}
        radius={1}
        distortionFrequency={0.326}
        displacementFrequency={0.815}
        distortionFrequencyIdle={0.326}
        distortionStrengthIdle={0.652}
        displacementFrequencyIdle={1.467}
        displacementStrengthIdle={0.2}
      />
      <Sphere
        number={4}
        radius={0.97}
        distortionFrequency={0.652}
        displacementFrequency={0.435}
        distortionFrequencyIdle={0.652}
        distortionStrengthIdle={1.087}
        displacementFrequencyIdle={0.924}
        displacementStrengthIdle={0.359}
      />
      <Sphere
        number={5}
        radius={0.95}
        distortionFrequency={0.652}
        displacementFrequency={0.543}
        distortionFrequencyIdle={0.652}
        distortionStrengthIdle={0.761}
        displacementFrequencyIdle={0.87}
        displacementStrengthIdle={0.196}
      />
      {isAiLoadingOpen && (
        <Html
          as="div"
          center // Adds a -50%/-50% css transform [ignored in transform mode]
          position={[0, deviceState > 1 ? -0.5 : -0.85, 0]}
        >
          <p style={{ fontWeight: 500, whiteSpace: "nowrap" }}>
            Aan het denken...
          </p>
        </Html>
      )}
    </group>
  );
}
