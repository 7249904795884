import React from "react";

export class ErrorBoundary extends React.Component<
  { set: React.Dispatch<Error | undefined>; children: React.ReactNode },
  { error: boolean }
> {
  state = { error: false };
  static getDerivedStateFromError = () => ({ error: true });
  componentDidCatch(err: Error) {
    this.props.set(err);
  }
  render() {
    return this.state.error ? null : this.props.children;
  }
}
