import useIsomorphicLayoutEffect from "../hooks/useIsomorphicLayoutEffect";
import React from "react";

export type SetBlock = false | Promise<null> | null;
export type UnblockProps = {
  set: React.Dispatch<React.SetStateAction<SetBlock>>;
  children: React.ReactNode;
};

export function Block({ set }: Omit<UnblockProps, "children">) {
  useIsomorphicLayoutEffect(() => {
    set(new Promise(() => null));
    return () => set(false);
  }, [set]);
  return null;
}
